import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { responsivePx, nl2br } from '../utils'

import {
  AspectRatio,
  Box,
  Flex,
  FromBerlin,
  GradientText,
  Heading,
  ImageText,
  Layout,
  SEO,
  SliceGrid,
  SliceGridItem,
  SlicePeople,
  SlicePeopleItem,
  FooterClaim,
  Footer,
  YouTubePlaylist,
} from '../components'

export const query = graphql`
  query homepageQuery($lang: String) {
    prismic {
      allHome_pages(lang: $lang) {
        edges {
          node {
            _meta {
              lang
              tags
              type
              uid
              alternateLanguages {
                lang
                type
                uid
              }
            }
            title
            claim
            body {
              __typename
              ... on PRISMIC_Home_pageBodyImage_text {
                type
                primary {
                  headline
                  copy
                  button_label
                  headline_color
                  style
                  content_image {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      height
                      name
                      size
                      url
                      width
                    }
                  }
                }
              }
              ... on PRISMIC_Home_pageBodyFrom_berlin {
                type
                primary {
                  headline
                }
              }
              ... on PRISMIC_Home_pageBodyGradient_text {
                type
                primary {
                  headline
                  button_label
                }
              }
              ... on PRISMIC_Home_pageBodyGrid {
                type
                primary {
                  headline
                  button_label
                }
                fields {
                  headline
                  copy
                  icon {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      height
                      name
                      url
                      width
                    }
                  }
                }
              }
              ... on PRISMIC_Home_pageBodyPeople {
                type
                primary {
                  headline
                }
                fields {
                  name
                  job_title
                  image {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      height
                      name
                      url
                      width
                    }
                  }
                }
              }
              ... on PRISMIC_Home_pageBodySlider {
                type
                primary {
                  headline
                  copy
                }
                fields {
                  headline
                  image {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      height
                      name
                      url
                      width
                    }
                  }
                }
              }
              ... on PRISMIC_Home_pageBodyYoutubeplaylist {
                type
              }
            }
          }
        }
      }
      allMeta_informations(lang: $lang) {
        edges {
          node {
            contact_link_label
            imprint_link_label
            imprint_link {
              _linkType
              ... on PRISMIC_Simple_page {
                _meta {
                  id
                  uid
                  lang
                  type
                }
              }
            }
            privacy_link_label
            privacy_link {
              _linkType
              ... on PRISMIC_Simple_page {
                _meta {
                  id
                  uid
                  lang
                  type
                }
              }
            }
            cookie_policy_label
            cookie_settings_label
            language_switch_label
            email
            footer_claim
            instagram {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            facebook {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            youtube {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            vimeo {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            linkedin {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`

const Homepage = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allHome_pages.edges.slice(0, 1).pop()
  if (!doc) return null

  const activeDocMeta = doc.node._meta
  const activeLang = activeDocMeta.lang.split('-')[0]
  const pageTitle = RichText.asText(doc.node.title)
  const claim = RichText.asText(doc.node.claim)

  const metaInformations = data.prismic.allMeta_informations.edges
    .slice(0, 1)
    .pop()
  const {
    instagram,
    facebook,
    youtube,
    vimeo,
    linkedin,
    email,
    footer_claim,
    contact_link_label,
    imprint_link_label,
    imprint_link,
    privacy_link_label,
    privacy_link,
    cookie_policy_label,
    cookie_settings_label,
    language_switch_label,
  } = metaInformations.node

  const contactLink = `mailto:${email}`

  const slices =
    doc.node.body &&
    doc.node.body.map((slice, index) => {
      const key = `${slice.type}-${index}`
      if (slice.type === 'image_text') {
        return (
          <ImageText
            headline={RichText.asText(slice.primary.headline)}
            copy={RichText.render(slice.primary.copy)}
            buttonLabel={slice.primary.button_label}
            buttonTarget={contactLink}
            headlineColor={slice.primary.headline_color}
            image={slice.primary.content_image}
            variant={slice.primary.style}
            key={key}
          />
        )
      } else if (slice.type === 'from_berlin') {
        return (
          <FromBerlin
            headline={RichText.asText(slice.primary.headline)}
            key={key}
          />
        )
      } else if (slice.type === 'gradient_text') {
        return (
          <GradientText
            headline={RichText.asText(slice.primary.headline)}
            buttonLabel={slice.primary.button_label}
            buttonTarget={contactLink}
            key={key}
          />
        )
      } else if (slice.type === 'grid') {
        return (
          <SliceGrid
            headline={RichText.asText(slice.primary.headline)}
            buttonLabel={slice.primary.button_label}
            buttonTarget={contactLink}
            key={key}
          >
            {slice.fields &&
              slice.fields.map((field, index) => (
                <SliceGridItem
                  headline={RichText.asText(field.headline)}
                  copy={RichText.render(field.copy)}
                  icon={field.icon}
                  key={`${key}-${index}`}
                />
              ))}
          </SliceGrid>
        )
      } else if (slice.type === 'people') {
        return (
          <SlicePeople
            headline={RichText.asText(slice.primary.headline)}
            key={key}
          >
            {slice.fields &&
              slice.fields.map((field, index) => (
                <SlicePeopleItem
                  name={RichText.asText(field.name)}
                  jobTitle={RichText.asText(field.job_title)}
                  image={field.image}
                  key={`${key}-${index}`}
                />
              ))}
          </SlicePeople>
        )
      } else if (slice.type === 'youtubeplaylist') {
        return <YouTubePlaylist {...slice} />
      }
      return null
    })

  return (
    <Layout activeDocMeta={activeDocMeta}>
      <SEO title={pageTitle} lang={activeLang} />
      <Box sx={{ position: 'relative' }}>
        <AspectRatio
          ratio={16 / 9}
          sx={{
            bg: 'bubblegumPinkTwo',
            iframe: {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Heading
            variant="heading1"
            as="h1"
            sx={{
              position: 'absolute',
              color: 'white',
              top: '40%',
              left: ['5%', null, null, '10%'],
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
            dangerouslySetInnerHTML={{
              __html: nl2br(RichText.asText(doc.node.title)),
            }}
          />
          <iframe
            src="https://player.vimeo.com/video/428236442?autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0&background=1"
            frameBorder="0"
            allow="autoplay"
            title="stage background video"
          ></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </AspectRatio>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: '0',
            left: 0,
            bg: 'bubblegumPinkTwo',
            width: '100%',
            height: responsivePx(60, 135),
            transform: 'translateY(50%) skewY(-2deg)',
            zIndex: '1',
            padding: '0 5%',
            fontSize: responsivePx(24, 64),
            fontWeight: 'bold',
            color: '#fff',
          }}
        >
          <span>{claim}</span>
        </Flex>
      </Box>

      <main>{slices}</main>
      <FooterClaim text={RichText.asText(footer_claim)} />
      <Footer
        activeDocMeta={activeDocMeta}
        instagramUrl={instagram && instagram.url}
        facebookUrl={facebook && facebook.url}
        youtubeUrl={youtube && youtube.url}
        vimeoUrl={vimeo && vimeo.url}
        linkedinUrl={linkedin && linkedin.url}
        contactLabel={contact_link_label}
        contactLink={contactLink}
        imprintLabel={imprint_link_label}
        imprintLink={imprint_link}
        privacyLabel={privacy_link_label}
        privacyLink={privacy_link}
        cookiePolicyLabel={cookie_policy_label}
        cookieSettingsLabel={cookie_settings_label}
        languageLabel={language_switch_label}
      ></Footer>
    </Layout>
  )
}

export default Homepage
